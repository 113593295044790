<template>
  <div class="login_container">
    <vue-particles color="#FFF"
        :particleOpacity="0.7"
        :particlesNumber="80"
        shapeType="circle"
        :particleSize="4"
        linesColor="#FFF"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="150"
        :moveSpeed="3"
        :hoverEffect="true"
        hoverMode="grab"
        :clickEffect="true"
        clickMode="push">
    </vue-particles>
    <div class="login_box">
      <div class="avator_box">
        <img src="../../assets/images/logo.png" alt="" />
      </div>

      <!-- 表单 -->
      <!-- loginFormRef为引用 -->
      <el-form ref="loginFormRef" :rules="loginFormRules" :model="loginForm" label-width="0px" class="login_form">
          <!-- 用戶名 -->
        <el-form-item prop="username">
          <el-input v-model="loginForm.username" prefix-icon="el-icon-user" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <!-- 密碼 -->
        <el-form-item prop="password">
          <el-input type="password" v-model="loginForm.password" prefix-icon="el-icon-lock" placeholder="请输入密码" @keyup.enter.native="login"></el-input>
        </el-form-item>
        <!-- 按鈕 -->
        <el-form-item class="btns">
          <el-button type="primary" @click="login" @keyup.enter.native="login">登录</el-button>
          <el-button type="info" @click="resetLoginForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import jwt from 'jsonwebtoken'

export default {
  data () {
    return {
      // 这是登录表单的数据
      loginForm: {
        username: '', // stu1
        password: '' // abc112233
      },
      userId: 0,
      // 表单的验证规则
      loginFormRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 2, max: 13, message: '长度在 2 到 13 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 2, max: 13, message: '长度在 2 到 13 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    // 重置表单
    resetLoginForm () {
      this.$refs.loginFormRef.resetFields()
    },
    login () {
      this.$refs.loginFormRef.validate(async vaild => {
        if (!vaild) return
        const { data: res } = await this.$http.post('login/', this.loginForm)
        // console.log('login: ', res)
        // if (res.ret !== 1000) return this.$message.error('登录失败!' + ' -' + res.msg)
        // this.$message.success('登录成功')
        if (res.ret !== 1000) return this.$notify({ title: '失败', message: '登录失败!' + ' -' + res.msg, type: 'error' })
        this.$notify({ title: '成功', message: '登录成功', type: 'success' })
        // 保存当前会话的token到sessionStorage
        window.sessionStorage.setItem('token', res.token)
        window.sessionStorage.setItem('activePath', 'welcome')
        const userId = jwt.decode(res.token).id
        // console.log('userId: ', userId)
        window.sessionStorage.setItem('user_id', userId)
        // 再跳转到home页面
        this.$router.push('/home')
      })
    }
  }
}
</script>

<style lang="less" scoped>
.login_container {
  background-color: #82CAEC;
  height: 100%;
}

.login_box {
  width: 450px;
  height: 300px;
  background-color: #ffff;
  border-radius: 5px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .avator_box {
    height: 130px;
    width: 130px;
    border: 2px solid #eee;
    border-radius: 50%;
    padding: 6px;
    box-shadow: 0 0 6px #ddd;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffff;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #eee;
    }
  }
}

.login_form {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;

}

.btns {
    display: flex;
    justify-content: flex-end;
}
</style>
