<template>
  <div>
    <el-carousel :interval="5000" arrow="always">
      <el-carousel-item v-for="item in 3" :key="item">
        <img :src="require('../assets/images/slide/' + item + '.jpg')" alt="" srcset="">
      </el-carousel-item>
    </el-carousel>
    <h2>巧于手、智于心</h2>
    <h4>巧手智心STEM欢迎您回来！</h4>
  </div>
</template>

<script>
export default {
  data () {
    return {
      queryInfo: {
        token: ''
      }
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
  .el-carousel{
    height: 100% !important;
    width: 100% !important;
    .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
    align-items: center;
  }
    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }
    .el-carousel__item:nth-child(2n+1) {
      background-color: #d3dce6;
    }
    img{
      height: 100% !important;
      width: 100% !important;
    }
  }
  h2{
    margin-top: 60px;
    text-align: center;
  }
  h4{
    margin-top: 10px;
    text-align: center;
  }
</style>
