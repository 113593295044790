<template>
  <el-container class="home_container">
    <!-- 头部区域 -->
    <el-header>
      <div>
        <img src="../assets/images/qzlogo.png" alt="" srcset="" @click="goHome('welcome')">
        <span>巧手智心STEM课程中心</span>
      </div>

      <el-menu
        :default-active= "activePath"
        mode="horizontal"
        background-color="#005f73"
        text-color="#fff"
        active-text-color="#ffd04b"
        router>
          <el-menu-item index="welcome" @click="saveNavPath('welcome')">首页</el-menu-item>
          <!-- <el-menu-item index="userCourses" @click="saveNavPath('userCourses')">我的课程</el-menu-item> -->
          <!-- <el-menu-item index="courses" @click="saveNavPath('courses')">更多课程</el-menu-item> -->
          <!-- <el-menu-item index="ideas" @click="saveNavPath('ideas')">奇思妙想</el-menu-item> -->
          <el-menu-item index="activity" @click="saveNavPath('activity')">活动中心</el-menu-item>
          <el-menu-item index="userCenter" @click="saveNavPath('userCenter')">用户中心</el-menu-item>
      </el-menu>
      <!-- <el-button type="info" @click="logout">退出</el-button> -->
      <el-button type="info" @click="logout">退出</el-button>
    </el-header>
    <!-- 主题区域 -->
    <el-main>
      <!-- 路由占位符 -->
      <router-view></router-view>
    </el-main>

    <!-- footer -->
    <el-footer style="height=20px">
      <p>版权所有&copy;  南京市格致星空软件科技有限公司</p>
    </el-footer>
  </el-container>
</template>

<script>
export default {
  data () {
    return {
      activePath: ''
    }
  },
  created () {
    this.activePath = window.sessionStorage.getItem('activePath')
  },
  methods: {
    logout () {
      this.$confirm('你确定要退出巧智课程中心吗?', '系统提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '退出成功!'
        })
        window.sessionStorage.clear()
        this.$router.push('/login')
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    // 保存活动页面
    saveNavPath (activePath) {
      // console.log(activePath)
      window.sessionStorage.setItem('activePath', activePath)
      this.activePath = activePath
    },
    goHome (activePath) {
      window.sessionStorage.setItem('activePath', activePath)
      this.activePath = activePath
      this.$router.push('/welcome')
    }
  }
}
</script>

<style lang="less" scoped>
.home_container{
  height: 100%;
}
.el-header{
  background-color: #005f73;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color:#FFF;
  font-size: 20px;
  > div{
    display: flex;
    align-items: center;
    img{
      height: 60px;
      cursor: pointer;
    }
    span{
      margin-left: 15px;
    }
  }
}
// .operationBtn{
//   display: flex;
//   justify-content: space-between;
//   text-align: center;
//   z-index: 9999;
//   margin-top: 10px;
//   margin-left: 5px;
//   z-index: 9999;
//   position: fixed;
//   >el-button{
//     height: 100%;
//   }
// }
// .el-aside{
//   background-color: #0a9396;
// }
.el-menu{
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-main{
  background-color: #FFF;
}

.el-footer{
  background: #0a9396; //005f73, 0a9396
  color: #FFF;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px!important;
}
</style>
